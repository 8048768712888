import { pickBy } from 'lodash-es';
import { createSelector } from 'reselect';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import { IDeal } from 'shared/models/Deal';
import { IDealSegment } from 'shared/models/DealSegment';
import { IDealType } from 'shared/models/DealType';
import { IDealTypeArBucket } from 'shared/models/DealTypeArBucket';
import { ItemsById } from 'shared/models/ItemsById';
import { IJobNumber, IJobNumberUserType } from 'shared/models/JobNumber';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { EntryType } from 'shared/models/sheet/Sheet';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { ISubmittingOrgGenworthBranch } from 'shared/models/SubmittingOrgGenworthBranch';
import { IStore } from 'store/configureStore';
import { ApproversFromFields } from 'store/entities/clients/clientsModel';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { selectClientApproversFrom } from 'store/entities/clients/selectors/timeAndPaySelectors';
import {
    ActivityTag,
    IActivity,
    IArea,
    IAssignment,
    IBackgroundCheckTemplate,
    IPayRange,
    IPhysicalDemandsTemplate,
    IProjectWithAssignmentBackend,
    ISubassignment,
    IWorkingConditionsTemplate,
} from 'store/entities/configuration/configurationModel';
import { CustomFieldType } from 'store/entities/customFields/model';
import { selectOrderedOfferLetterCustomFields } from 'store/entities/customFields/selectors';
import { selectSheet, selectTimeSheetById } from 'store/entities/timesheet/selectors';
import createSelectorSortedByNameFromById from 'store/utils/selectors/createSelectorSortedByNameFromById';

const filterDeletedActivity = (activity: IActivity) => !activity.deleted_at;

export const selectIsActivitiesLoading = (state: IStore): boolean => state.configuration.isActivitiesLoading;
export const selectIsActivitiesLoaded = (state: IStore): boolean => Boolean(state.configuration.isActivitiesLoaded);
export const selectActivitiesById = (state: IStore): ItemsById<IActivity> => state.configuration.activitiesById;
export const selectActivitiesByIdWithoutDeleted = createSelector(
    selectActivitiesById,
    activitiesById => pickBy(activitiesById, filterDeletedActivity),
);
export const selectTimeActivities = createSelector(
    selectActivitiesByIdWithoutDeleted,
    activitiesById => Object.values(activitiesById).filter(
        activity => activity.is_active && activity.sheet_type === EntryType.TIME,
    ),
);
export const selectDefaultTimeActivities = createSelector(
    selectTimeActivities,
    timeActivities => timeActivities.filter(activity => activity.tags?.includes(ActivityTag.Default)),
);
const selectActivitiesIncludingDeleted = createSelector(
    selectActivitiesById,
    activitiesById => Object.values(activitiesById),
);
export const selectActivities = createSelector(
    selectActivitiesIncludingDeleted,
    activitiesIncludingDeleted => activitiesIncludingDeleted.filter(filterDeletedActivity),
);
export const selectTravelExpenseActivities = createSelector(
    selectActivities,
    activities => activities.filter(activity => activity.tags?.includes(ActivityTag.Travel)),
);
export const selectClientHasActivities = createSelector(
    selectActivities,
    activities => activities.filter((activity: IActivity) => activity.is_active).length !== 0,
);
export const selectClientHasActiveTimeActivities = createSelector(
    selectActivities,
    activities => activities.filter((activity: IActivity) =>
        activity.sheet_type === EntryType.TIME && activity.is_active).length !== 0,
);
export const selectClientHasActiveExpenseActivities = createSelector(
    selectActivities,
    activities => activities.filter((activity: IActivity) =>
        activity.sheet_type === EntryType.EXPENSE && activity.is_active).length !== 0,
);

export const selectActivitiesByTaskId = (state: IStore) => state.configuration.activitiesByTaskId;

export const selectActivity = (id?: string) => (state: IStore): IActivity => state.configuration.activitiesById[id || ''];
export const selectAreasById = (state: IStore) => state.configuration.areasById;
export const selectIsAreasLoading = (state: IStore) => state.configuration.isAreasLoading;
export const selectAssignmentsById = (state: IStore) => state.configuration.assignmentsById;
export const selectAssignments = (state: IStore) => {
    const assignmentsById = selectAssignmentsById(state);
    return Object.values(assignmentsById);
};
export const selectAreaBySheetId = (id: string) => (state: IStore): IArea | undefined =>
    state.configuration.areasById[selectSheet(id)(state)?.area_id ?? ''];
export const selectTasksById = (state: IStore) => state.configuration.tasksById;
export const selectTaskById = (taskId: string) => (state: IStore) => state.configuration.tasksById[taskId];
export const selectTasks = (state: IStore) => Object.values(selectTasksById(state));
export const selectAssignmentById = (id?: string) => (state: IStore) => selectAssignmentsById(state)[id || ''];
const selectApproversCount = (sheetId: string) => (state: IStore) => {
    const sheet = selectTimeSheetById(sheetId)(state);
    const approversFrom = selectClientApproversFrom(state);
    if (sheet && approversFrom === ApproversFromFields.FromAssignment){
        const subassignment = selectSubassignmentsByIds(state)[sheet.subassignment_id];
        return subassignment?.managers?.length || 0;
    }
    return 1;
};
export const selectApproversCountBySheets = (sheetIds: string[]) => (state: IStore) => {
    return sheetIds.reduce((acc, sheetId) => ({
        ...acc,
        [sheetId]: selectApproversCount(sheetId)(state),
    }), {}) as {[key: string]: number};
};

export const selectProjectIdByAssignmentId = (id: string) => (state: IStore) => selectAssignmentProjectIds(state)[id];
export const selectAssignmentAreaId = (state: IStore) => state.configuration.assignmentAreaId;
export const selectAssignmentProjectIds = (state: IStore) => state.configuration.projectIdsByAssignmentId;
export const selectProjectsWithAssignments = (state: IStore): IProjectWithAssignmentBackend[] =>
    state.configuration.projectWithAssignments;
export const selectProjectsById = (state: IStore) => state.configuration.projectsById;

export const selectAssignmentCustomFieldMapping = createSelector(
    selectOrderedOfferLetterCustomFields,
    fields => {
        const jobOrderField = fields.find(field => field.headway_connect_field?.key === CustomFieldType.JobOrder);
        return {
            job_order_field_id: jobOrderField?.id || '',
        };
    },
);

/**
 * Positions
 */
export const selectPositionsById = (state: IStore): Record<string, IPosition> => state.configuration.positionsById;
export const selectIsPositionsLoading = (state: IStore) => state.configuration.isPositionsLoading;
export const selectPositions = (state: IStore): IPosition[] =>
    Object.values(selectPositionsById(state)).filter(position => !position.deleted);
export const selectPositionById = (id = '') => (state: IStore) => state.configuration.positionsById[id];

/**
 * Selects all available positions for the current client
 */
export const selectPositionsForClient = createSelector(
    selectCurrentClientId,
    selectPositions,
    function filterPositionsForClient(
        clientId: string | null,
        positions: IPosition[],
    ): IPosition[] {
        if (clientId) {
            return positions.filter(item => item.client_id === clientId);
        }
        return [];
    },
);

/**
 * Controlling Orgs
 */
export const selectControllingOrgsById = (state: IStore) => {
    return state.configuration.controllingOrgsById;
};
export const selectAllControllingOrgs = (state: IStore): IControllingOrg[] => {
    return Object.values(selectControllingOrgsById(state));
};
export const selectActiveControllingOrgs = (state: IStore) => {
    return selectAllControllingOrgs(state).filter(org => org.is_active);
};
export const selectControllingOrgById = (id = '') => (state: IStore) => {
    const controllingOrgsById = selectControllingOrgsById(state);
    return controllingOrgsById[id];
};
export const selectControllingOrgsIsLoading = (state: IStore) => {
    return state.configuration.controllingOrgsIsLoading;
};

/**
 * Deals
 */
export const selectDealsById = (state: IStore) => state.configuration.dealsById;
export const selectDealById = (id = '') => (state: IStore) => selectDealsById(state)[id];
export const selectDeals = (state: IStore): IDeal[] => Object.values(selectDealsById(state));
export const selectDealsIsLoading = (state: IStore) => {
    return state.configuration.dealsIsLoading;
};
export const selectDealByNumber = (dealNumber?: string) => (state: IStore) => {
    const deals = selectDeals(state);
    const deal = deals.find(d => d.deal_number === dealNumber);
    return deal || null;
};

/**
 * Deal Types
 */
export const selectDealTypesById = (state: IStore) => state.configuration.dealTypesById;
export const selectDealTypeById = (id = '') => (state: IStore) => selectDealTypesById(state)[id];
export const selectDealTypes = (state: IStore) => (
    Object.values<IDealType>(selectDealTypesById(state)).sort((d0, d1) => {
        return d0.name.localeCompare(d1.name);
    })
);
export const selectDealTypesAreLoading = (state: IStore) => state.configuration.dealsTypesAreLoading;

/**
 * Deal Segments
 */
export const selectDealSegments = (state: IStore): IDealSegment[] => {
    const { configuration } = state;
    return configuration.dealSegments.sort((dealSegment1: IDealSegment, dealSegment2: IDealSegment) => {
        return dealSegment1.name.localeCompare(dealSegment2.name);
    });
};

export const selectDealSegmentsIsLoading = (state: IStore) => state.configuration.dealsSegmentsAreLoading;

/**
 * Deal Type Pay Code and Ar_Bucket Association
 */
export const selectDealTypePayCodeArBuckets = (state: IStore): IDealTypeArBucket[] => (
    Object.values(state.configuration.dealTypePayCodeArBuckets)
);
export const selectDealTypeArBuckets = (state: IStore): string[] => {
    return state.configuration.dealTypeArBuckets.sort((arBucket0 :string, arBucket1: string) => {
        return arBucket0.localeCompare(arBucket1);
    });
};
export const selectDealTypePayCodeArBucketsAreLoading = (state: IStore) => (
    state.configuration.dealTypePayCodeArBucketsAreLoading
);
export const selectDealTypeArBucketsAreLoading = (state: IStore) => (
    state.configuration.dealTypeArBucketsAreLoading
);

/**
 * Submitting Orgs
 */
export const selectSubmittingOrgsById = (state: IStore): Record<string, ISubmittingOrg> => {
    return state.configuration.submittingOrgsById;
};
export const selectSubmittingOrgs = createSelector(
    selectSubmittingOrgsById,
    submittingOrgsById => {
        const orgs = Object.values(submittingOrgsById);
        return orgs.sort((org1, org2) => {
            return org1.client_site_name.localeCompare(org2.client_site_name);
        });
    },
);

export const selectSubmittingOrgById = (id = '') => (state: IStore) => {
    const submittingOrgsById = selectSubmittingOrgsById(state);
    return submittingOrgsById[id];
};
export const selectSubmittingOrgsIsLoading = (state: IStore) => {
    return state.configuration.submittingOrgsIsLoading;
};

/**
 * Submitting Org Locations
 */
export const selectSubmittingOrgLocationsById = (state: IStore) => {
    return state.configuration.submittingOrgLocationsById;
};
export const selectSubmittingOrgLocations = (state: IStore) => {
    return Object.values(selectSubmittingOrgLocationsById(state));
};
export const selectSubmittingOrgLocationById = (id = '') => (state: IStore) => {
    const submittingOrgLocationsById = selectSubmittingOrgLocationsById(state);
    return submittingOrgLocationsById[id];
};
export const selectSubmittingOrgLocationsIsLoading = (state: IStore) => {
    return state.configuration.submittingOrgLocationsIsLoading;
};

/**
 * Submitting Org Genworth Branches
 */
export const selectSubmittingOrgGenworthBranchesById = (state: IStore)
: Record<string, ISubmittingOrgGenworthBranch> => {
    return state.configuration.submittingOrgGenworthBranchesById;
};
export const selectSubmittingOrgGenworthBranches = (state: IStore): ISubmittingOrgGenworthBranch[] => {
    const branches = Object.values(selectSubmittingOrgGenworthBranchesById(state));
    return branches.sort((branch1, branch2) => {
        return Number(branch1.code) - Number(branch2.code);
    });
};
export const selectSubmittingOrgGenworthBranchById = (id = '') => (state: IStore) => {
    const submittingOrgGenworthBranchesById = selectSubmittingOrgGenworthBranchesById(state);
    return submittingOrgGenworthBranchesById[id];
};
export const selectSubmittingOrgGenworthBranchesIsLoading = (state: IStore) => {
    return state.configuration.submittingOrgGenworthBranchesIsLoading;
};

/**
 * Work locations
 */
export const selectLocationsById = (state: IStore) => state.configuration.locationsById;
export const selectLocations = (state: IStore) =>
    (Object.values(selectLocationsById(state)) as Array<ILocation>).filter(location => !location.deleted);
export const selectLocationById = (id = '') => (state: IStore) => state.configuration.locationsById[id];
export const selectLocationsLoading = (state: IStore) => state.configuration.locationsLoading;

/**
 * Physical demands
 */
export const selectPhysicalDemandsById = (state: IStore) => state.configuration.physicalDemandsById;
export const selectPhysicalDemands = (state: IStore) => Object.values(selectPhysicalDemandsById(state));
export const selectPhysicalDemandById = (id = '') => (state: IStore) => selectPhysicalDemandsById(state)[id];
export const selectIsPhysicalDemandsTemplatesLoading = (state: IStore) =>
    state.configuration.isPhysicalDemandsTemplatesLoading;
export const selectPhysicalDemandsSortedByName = (
    createSelectorSortedByNameFromById<IPhysicalDemandsTemplate>(selectPhysicalDemandsById)
);

/**
 * Working conditions
 */
export const selectWorkingConditionsById = (state: IStore) => state.configuration.workingConditionsById;
export const selectWorkingConditions = (state: IStore) => Object.values(selectWorkingConditionsById(state));
export const selectWorkingConditionById = (id = '') => (state: IStore) => selectWorkingConditionsById(state)[id];
export const selectIsWorkingConditionsLoading = (state: IStore) => state.configuration.isWorkingConditionsLoading;
export const selectWorkingConditionsSortedByName = (
    createSelectorSortedByNameFromById<IWorkingConditionsTemplate>(selectWorkingConditionsById)
);

/**
 * Background check templates
 */
export const selectBackgroundCheckTemplatesById = (state: IStore) => state.configuration.backgroundCheckTemplatesById;
export const selectBackgroundCheckTemplates = (state: IStore) =>
    Object.values(selectBackgroundCheckTemplatesById(state));
export const selectBackgroundCheckTemplateById = (id = '') => (state: IStore) =>
    selectBackgroundCheckTemplatesById(state)[id];
export const selectIsBackgroundCheckTemplatesLoading = (state: IStore) =>
    state.configuration.isBackgroundCheckTemplatesLoading;
export const selectBackgroundCheckTemplatesSortedByName = (
    createSelectorSortedByNameFromById<IBackgroundCheckTemplate>(selectBackgroundCheckTemplatesById)
);

/**
 * Cost Center
 */
export const selectCostCentersById = (state: IStore) => state.configuration.costCentersById;
export const selectCostCenters = (state: IStore) => Object.values(selectCostCentersById(state));
export const selectCostCenterById = (id?: string | null) => (state: IStore) => selectCostCentersById(state)[id || ''];
export const selectCostCentersIsLoading = (state: IStore) => state.configuration.costCentersIsLoading;

/**
 * Job Numbers
 */
export const selectJobNumbersById = (state: IStore): Record<string, IJobNumber> => state.configuration.jobNumbersById;
export const selectIsJobNumbersLoading = (state: IStore) => state.configuration.isJobNumbersLoading;
export const selectJobNumberById = (id?: string | null) => (state: IStore) =>
    selectJobNumbersById(state)[id || ''];
export const selectJobNumbers = (state: IStore) => Object.values(selectJobNumbersById(state));

/**
 * Job Numbers User Types
 */
export const selectJobNumbersUserTypesById = (state: IStore) => state.configuration.jobNumbersUserTypesById;
export const selectIsJobNumbersUserTypesLoading = (state: IStore) => state.configuration.isJobNumbersUserTypesLoading;
export const selectJobNumbersUserTypes = (state: IStore): IJobNumberUserType[] =>
    Object.values(selectJobNumbersUserTypesById(state));
export const selectJobNumbersUserTypeById = (id?: string | null) => (state: IStore) =>
    selectJobNumbersUserTypesById(state)[id || ''];

/**
 * Subassignments
 */
export const selectSubassignmentsByIds = (state: IStore) => state.configuration.subassignmentsById;
export const selectSubassignmentsByUserId = (userId?: string) => (state: IStore): ISubassignment[] => {
    if (!userId) {
        return [];
    }
    const subassignments = Object.values(selectSubassignmentsByIds(state)) as ISubassignment[];
    const assignments = Object.values(selectAssignmentsById(state)) as IAssignment[];
    const userAssignmentIds = assignments
        .filter(assignment => assignment.user_id === userId)
        .map(assignment => assignment.id);
    return subassignments.filter(subassignment => userAssignmentIds.includes(subassignment.assignment_id));
};
export const selectSubassignmentsIsLoading = (state: IStore) => state.configuration.isLoadingSubassignments;

/**
 * Pay ranges
 */
export const selectPayRangesByIds = (state: IStore) => state.configuration.payRangesById;

export const selectPayRangesIds = createSelector(
    selectPayRangesByIds,
    (dictionary: ItemsById<IPayRange>): Array<string> => Object.keys(dictionary),
);

export const selectPayRanges = createSelector(
    selectPayRangesByIds,
    (dictionary: ItemsById<IPayRange>): Array<IPayRange> => Object.values(dictionary),
);

export const selectPayRangeLoading = (state: IStore) => state.configuration.payRangeLoading;
