import { Box } from '@material-ui/core';
import { PayrollButton } from 'modules/payrollProcessorHub/components/PayrollAction/PayrollButton';
import {
    ApproveButton,
    ApproveButtonBatch,
} from 'modules/payrollProcessorHub/components/SheetStatusAction/ApproveButton';
import { RejectButton, RejectButtonBatch } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectButton';
import { getSheetGroupId, useActivePayrollSheetStatus } from 'modules/payrollProcessorHub/store/helpers';
import { ISheetGroupIdWithClient, PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import {
    selectCheckedCalculationGroups,
    selectCheckedTimeAndExpenseSheetGroups,
} from 'modules/payrollProcessorHub/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { PayrollBatchButton } from '../PayrollAction/PayrollBatchButton';

export const PayrollSheetBatchActions = () => {
    const status = useActivePayrollSheetStatus();

    //Single time & expense sheet in one group
    const groups = useSelector(selectCheckedTimeAndExpenseSheetGroups);
    const groupIds = groups.map(group => getSheetGroupId(group));
    const groupWithClientIds: ISheetGroupIdWithClient[] = groups.map(group => ({
        timeSheetId: group.time_sheet_id,
        expenseSheetId: group.expense_sheet_id,
        clientId: group.client_id,
    }));
    const userHasApprovePermission = useSelector(selectIsUserHasPermission(Permission.CanApprovePphSheet));

    //Several time & expense sheets in one group
    const calculationGroups = useSelector(selectCheckedCalculationGroups);

    return (
        <Box
            display="flex"
            flexDirection="row"
            height="max-content"
        >
            {status === PayrollSheetTabs.APPROVED && (
                <>
                    {groupIds.length > 0 && (
                        <PayrollButton groupIds={groupWithClientIds} />
                    )}
                    {calculationGroups.length > 0 && (
                        <PayrollBatchButton calculationGroups={calculationGroups} />
                    )}
                </>
            )}
            {status === PayrollSheetTabs.SUBMITTED && userHasApprovePermission && (
                <>
                    {groupIds.length > 0 && (
                        <>
                            <RejectButton groupIds={groupIds} />
                            <Box m={1} />
                            <ApproveButton groupIds={groupIds} />
                        </>
                    )}
                    {calculationGroups.length > 0 && (
                        <>
                            <RejectButtonBatch calculationGroups={calculationGroups} />
                            <Box m={1} />
                            <ApproveButtonBatch calculationGroups={calculationGroups} />
                        </>
                    )}
                </>
            )}
        </Box>
    );
};
