import { searchJobNumbersEmployees } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import {
    selectAssignUserJobNumberId,
    selectIsAssignUserUpdating,
} from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/selectors';
import { setEditJobNumberId, assignUserToJobNumber } from 'modules/settings/submodules/clients/jobNumber/store/actions';
import { UserSearch } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/components/UserSearch';
import { UserSearchResults } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/components/UserSearchResults';
import { formBasicSpacing, useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { selectJobNumberById } from 'store/entities/configuration/configurationSelectors';
import { selectUserById } from 'store/entities/users/selectors';

interface IJobNumberAssignUserProps {
    onClose?: () => void;
}

export const JobNumberAssignUser = ({ onClose }: IJobNumberAssignUserProps) => {
    const classes = useSettingsFormStyles();
    const dispatch = useDispatch();
    const jobNumberId = useSelector(selectAssignUserJobNumberId);
    const jobNumber = useSelector(selectJobNumberById(jobNumberId || ''));
    const assignedEmployee = useSelector(selectUserById(jobNumber?.user_id || ''));
    const isLoading = useSelector(selectIsAssignUserUpdating);

    const [selectedUserId, setSelectedUserId] = useState<string | null>(assignedEmployee?.id || null);
    const selectedUser = useSelector(selectUserById(selectedUserId ?? ''));
    useEffect(() => {
        if (assignedEmployee) {
            dispatch(searchJobNumbersEmployees.success([assignedEmployee]));
        }
        return () => {
            dispatch(searchJobNumbersEmployees.success([]));
        };
    }, [dispatch, assignedEmployee]);

    const onSave = useCallback(() => {
        if (jobNumberId && selectedUserId) {
            dispatch(assignUserToJobNumber.init({
                job_number_id: jobNumberId,
                user_id: selectedUserId,
                talent_id: selectedUser?.talent_id,
            }));
        }
    }, [dispatch, jobNumberId, selectedUser?.talent_id, selectedUserId]);
    const onBack = useCallback(() => {
        dispatch(setEditJobNumberId(jobNumberId));
        if (onClose) {
            onClose();
        }
    }, [dispatch, jobNumberId, onClose]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            m={1}
            style={{ minHeight: '420px' }}
        >
            <UserSearch />
            <Box
                display="flex"
                flexGrow={1}
                ml={2}
                mr={2}
            >
                <UserSearchResults
                    selectedUserId={selectedUserId}
                    onSelectUser={setSelectedUserId}
                />
            </Box>
            <Box m={formBasicSpacing} mt={2}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={onBack}
                    disabled={isLoading}
                >
                    Back
                </Button>
                <ButtonWithLoader
                    onClick={onSave}
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    isLoading={isLoading}
                    data-testid="submit_update_button"
                >
                    Assign & Save
                </ButtonWithLoader>
            </Box>
        </Box>
    );
};
