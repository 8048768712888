import { getUserAvatar } from 'modules/profile/store/profileActions';
import { matchPath } from 'react-router';
import { generatePath } from 'react-router-dom';
import { ToastCustomContentKey } from 'shared/components/toasts/customContentToastMapper';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { routes } from 'shared/routes';
import { AuthBaseApi, getAuthApi } from 'shared/utils/authApi';
import baseApi from 'shared/utils/baseApi';
import { browserHistory } from 'shared/utils/browserHistory';
import { api } from 'store/components/auth/api';
import {
    authByPassword,
    authByToken,
    authTokenUpdate,
    loginAsUser,
    setClientUserPermission,
    logout, keycloakLogout, registrationRedirect, keycloakLogin, impersonateSwitchBack, resetState, authInitial,
} from 'store/components/auth/authActions';
import { IUserTokenInfo, Permission } from 'store/components/auth/authModels';
import {
    selectCurrentUser,
    selectGlobalPermissions,
    selectIsUserHasPermission,
    selectIsUserImpersonate,
} from 'store/components/auth/selectors';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { setClientId, setTenantSuccess } from 'store/entities/clients/clientsAction';
import { selectAvailableClients, selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import Appcues from 'shared/services/Appcues/Appcues';
import { getUsers } from 'store/entities/users/actions';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    call, put, select, takeLatest, take, delay,
} from 'typed-redux-saga';
import { some } from 'lodash-es';
import { IUserInfoClient } from 'shared/models/User';
import { millisecondsInMinute } from 'utils/constants';
import { HeadwayError } from 'utils/errors';

function* redirectToClientRoot(clientId?: string) {
    if (clientId !== undefined && clientId !== null) {
        const clients = yield select(selectAvailableClients);
        if (clients[clientId] === undefined) {
            console.log('Potentially empty client');
        }
        browserHistory.push(generatePath(routes.CLIENT.ROOT, { client_id: clientId }));
        return;
    }
    browserHistory.replace(routes.HOME);
}

export function* authByTokenSaga() {
    const authApi = getAuthApi();
    try {
        yield call([authApi, authApi.init]);
        const user: IUserTokenInfo = yield* call([authApi, authApi.refreshTokens]);
        yield put(authByToken.success({ user }));
    } catch (error) {
        yield put(authByToken.error(null));
    }
}

export function* authByTokenIfExistsSaga() {
    const authApi = getAuthApi();
    try {
        yield call([authApi, authApi.init]);
        const user: IUserTokenInfo | null = yield* call([authApi, authApi.refreshByTokenIfExists]);
        if (user) {
            yield put(authByToken.success({ user }));
        }
    } catch (error) {
        yield put(authByToken.error(null));
    }
}

function* authSuccessSaga(action: ReturnType<typeof authByToken.success>
| ReturnType<typeof authByPassword.success>) {
    const { payload: { user } } = action;
    Appcues.user(user.sub, user.email, `${user.given_name} ${user.family_name}`, Boolean(user?.act?.sub));
    Appcues.setPermissions(user.global_permissions || []);
    yield put(getUserAvatar.init());
}

function* authTokenUpdateSaga() {
    const authApi = getAuthApi();
    try {
        const user: IUserTokenInfo = yield* call([authApi, authApi.refreshTokens]);
        yield put(authTokenUpdate.success({ user }));
    } catch (error) {
        yield put(authTokenUpdate.error(null));
    }
}

export function* authInitWithTokenWatcher() {
    yield* takeLatest([authInitial.initType], authByTokenIfExistsSaga);
}

export function* authSuccessWatcher() {
    yield* takeLatest([authByPassword.successType, authByToken.successType], authSuccessSaga);
}

export function* authByTokenWatcher() {
    yield takeLatest(authByToken.initType, authByTokenSaga);
}

function* authTokenUpdateWatcher() {
    yield takeLatest(authTokenUpdate.initType, authTokenUpdateSaga);
}

function* setAppClientSaga(
    action: ReturnType<typeof authByToken.success> | ReturnType<typeof authTokenUpdate.success>,
) {
    const { payload: { user } } = action;
    const configurationClientId = yield select(selectCurrentClientId);
    if (configurationClientId && some(user?.clients, client => client.client_id === configurationClientId)) {
        yield put(setClientId(configurationClientId));
    }
}

export function* setAppClientSagaWatcher() {
    yield* takeLatest([
        authByPassword.successType,
        authTokenUpdate.successType,
        authByToken.successType,
    ], setAppClientSaga);
}

export function* clientRedirectionSaga(
    action: ReturnType<typeof authByToken.success> | ReturnType<typeof authTokenUpdate.success>,
) {
    const { pathname } = browserHistory.location;
    if (matchPath(
        pathname,
        {
            path: routes.HOME,
            exact: true,
            strict: true,
        },
    )) {
        const { payload: { user } } = action;
        const userHasAccessAllClients = yield select(selectIsUserHasPermission(Permission.AccessAllClients));
        if (userHasAccessAllClients) {
            return;
        }
        if (user?.clients && user?.clients.length === 1) {
            const clientId = user?.clients[0]?.client_id;
            browserHistory.push(generatePath(routes.CLIENT.ROOT, { client_id: clientId }));
        }
    }
}

export function* clientRedirectionSagaWatcher() {
    yield takeLatest([
        authByPassword.successType,
        authByToken.successType,
    ], clientRedirectionSaga);
}

export function* logoutSaga(action: ReturnType<typeof logout> | ReturnType<typeof keycloakLogout>) {
    const authApi = getAuthApi();
    const fullLogout = action.payload !== false;
    yield* call([authApi, authApi.logout], fullLogout);
}
export function* logoutWatcher() {
    yield takeLatest([logout.action, keycloakLogout.action], logoutSaga);
}

export function* setAuthTenantSaga({ payload: clientId }: ReturnType<typeof setClientId>) {
    const user = yield* select(selectCurrentUser);
    const globalPermissions = yield* select(selectGlobalPermissions);

    let permissions: Permission[] = [];
    if (clientId && user) {
        baseApi.clientId = clientId;

        const currentClient = user.clients.find((client: IUserInfoClient) => client.client_id === clientId);
        permissions = currentClient?.permissions || globalPermissions || [];
        Appcues.setPermissions(permissions);
    } else {
        baseApi.clientId = null;
    }
    yield put(setClientUserPermission([...globalPermissions, ...permissions]));
    yield put(setTenantSuccess(clientId));
    if (user) {
        yield put(getUsers.init({ ids: user?.id }));
    }
}

function* setTenantWatcher() {
    yield* takeLatest(setClientId.action, setAuthTenantSaga);
}

export function* loginAsUserSaga({ payload }: ReturnType<typeof loginAsUser.init>) {
    const userAlreadyImpersonated = yield select(selectIsUserImpersonate);
    if (userAlreadyImpersonated) {
        throw new HeadwayError('You are already logged as other user. Switch back to your account and try again.');
    }

    const { userId, clientId } = payload;
    const authApi = getAuthApi();
    yield* call([authApi, authApi.refreshTokens]);
    authApi.saveOriginalImpersonateToken();
    const userTokens = yield* call(api.getImpersonateUserToken, userId, clientId);
    yield put(resetState());
    yield call([authApi, authApi.setAuthTokens], userTokens.access_token, userTokens.refresh_token);
    yield* put(authByToken.init());
    yield put(loginAsUser.success());
    yield redirectToClientRoot(clientId);

    const authResult = yield take([authByToken.successType, authByToken.errorType]);
    if (authResult.type === authByToken.successType) {

        yield put(setGlobalToast({
            severity: IModalSeverity.Success,
            title: '',
            autoHideDuration: null,
            customComponentKey: ToastCustomContentKey.ImpersonationSuccess,
        }));
    }
}

function* loginAsUserWatcher() {
    yield* takeLatest(loginAsUser.initType, withBackendErrorHandler(
        loginAsUserSaga,
        loginAsUser.error,
        'Unable to login as user',
    ));
}

export function* impersonateSwitchBackSaga() {
    const clientId = yield select(selectCurrentClientId);
    yield put(setGlobalToast(null));
    const authApi = getAuthApi();
    const { accessToken, refreshToken } = authApi.getOriginalImpersonateToken();
    yield put(keycloakLogout(false));
    yield call([authApi, authApi.setAuthTokens], accessToken, refreshToken);
    yield* put(authByToken.init());
    authApi.removeOriginalImpersonateToken();
    yield redirectToClientRoot(clientId);
    yield put(impersonateSwitchBack.success());
}

function* impersonateSwitchBackWatcher() {
    yield* takeLatest(impersonateSwitchBack.initType, withBackendErrorHandler(
        impersonateSwitchBackSaga,
        impersonateSwitchBack.error,
        'Unable to switch back',
    ));
}

function* keycloakLoginSaga() {
    const authApi = getAuthApi();
    const user: IUserTokenInfo = yield* call([authApi, authApi.refreshTokens]);
    yield put(authByToken.success({ user }));
}

function* keycloakLoginWatcher() {
    yield takeLatest(keycloakLogin.action, keycloakLoginSaga);
}

function* registrationRedirectSaga(action: ReturnType<typeof registrationRedirect>) {
    const authApi = getAuthApi();
    yield call([authApi, authApi.register], action.payload);
}

function* registrationRedirectWatcher() {
    yield takeLatest(registrationRedirect.action, registrationRedirectSaga);
}

export function* authTokenRefreshWatcher() {
    while (true) {
        yield call(delay, millisecondsInMinute);
        const authApi = getAuthApi();
        const token = yield call([authApi, authApi.getParsedRefreshToken]);
        if (token) {
            if (AuthBaseApi.tokenIsExpiresSoon(token)) {
                yield put(authTokenUpdate.init());
            }
        }
    }
}

export default [
    authSuccessWatcher,
    authByTokenWatcher,
    logoutWatcher,
    setTenantWatcher,
    authTokenUpdateWatcher,
    setAppClientSagaWatcher,
    clientRedirectionSagaWatcher,
    loginAsUserWatcher,
    keycloakLoginWatcher,
    registrationRedirectWatcher,
    impersonateSwitchBackWatcher,
    authTokenRefreshWatcher,
];
