import { FormikProps } from 'formik';
import { PayRateType, PayType } from 'shared/models/PaySettings';
import { IEmployeeUserInfo, IUserInfo } from 'shared/models/User';
import { IBackgroundCheckTemplate, IPhysicalDemandsTemplate, IWorkingConditionsTemplate } from 'store/entities/configuration/configurationModel';

export interface IOfferLetterFormValues {
    userId?: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    jobOrderId: string | null;
    startDate: string;
    payRate: string;
    payRateType: PayRateType;
    payType: PayType;
    approvers: (IUserInfo | null)[];
    template?: string | null;
    hours?: number | null;
    employeeType?: string | null;
    workingConditions?: string;
    workingConditionsTemplate: IWorkingConditionsTemplate | null;
    physicalDemands?: string;
    physicalDemandsTemplate: IPhysicalDemandsTemplate | null;
    backgroundChecks?: string;
    backgroundChecksTemplate: IBackgroundCheckTemplate | null;
    employee_number?: string | null;
}

export const defaultOfferLetterValues: IOfferLetterFormValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    jobOrderId: null,
    startDate: '',
    payRate: '',
    payRateType: PayRateType.PER_HOUR,
    payType: PayType.Hourly,
    approvers: [
        null,
    ],
    template: null,
    hours: null,
    employeeType: null,
    workingConditions: '',
    workingConditionsTemplate: null,
    physicalDemands: '',
    physicalDemandsTemplate: null,
    backgroundChecks: '',
    backgroundChecksTemplate: null,
    employee_number: null,
};

export interface IOfferLetterUser extends IEmployeeUserInfo, Omit<Partial<IUserInfo>, 'first_name' | 'last_name' | 'cell_phone' | 'email' | 'job_title'> {
}

export interface IOfferLetterFormProps extends FormikProps<IOfferLetterFormValues> {
    isLoading?: boolean;
    onCancel: () => void;
    selectedUser?: IOfferLetterUser;
}
