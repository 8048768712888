import WarningIcon from '@material-ui/icons/Warning';
import { useFeature } from '@optimizely/react-sdk';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useHeaderTabsStyles from 'shared/components/common/headerTabs/TabStyles';
import { selectIsUserHasRequiredActionOnPendingPayRateTab } from 'modules/offerLetter/store/selectors';
import { selectIsPayRangesEnabledForClient } from 'store/entities/clients/selectors/configurationSelectors';
import {
    OfferLetterStatusSlug,
    offerLetterTabsSorted,
} from 'modules/offerLetter/store/model';
import { FeatureSwitches } from 'utils/featureSwitches';

export function useOfferLetterTabs() {
    const isPayRangeEnabledForClient = useSelector(selectIsPayRangesEnabledForClient);
    const isClientHasPendingPayRateOffers = useSelector(selectIsUserHasRequiredActionOnPendingPayRateTab);
    const [disableOfferLetterPendingI9Status] = useFeature(FeatureSwitches.disableOfferLetterPendingI9Status);
    const classes = useHeaderTabsStyles();

    return useMemo(() => {
        const warningIconMixin = isClientHasPendingPayRateOffers ? {
            icon: <WarningIcon className={classes.warningClass} />,
            classes: {
                wrapper: classes.iconLabelWrapper,
            },
        } : {};
        let result = [...offerLetterTabsSorted];
        if (isPayRangeEnabledForClient) {
            result.unshift({
                label: 'Rate Approval',
                value: OfferLetterStatusSlug.PendingPayRateApproval,
                ...warningIconMixin,
            });
        }
        if (disableOfferLetterPendingI9Status) {
            result = result.filter(tabItem => tabItem.value !== OfferLetterStatusSlug.PendingManagerApproval);
        }
        return result;
    }, [isPayRangeEnabledForClient, isClientHasPendingPayRateOffers, disableOfferLetterPendingI9Status, classes]);
}
