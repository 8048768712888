import { IHeaderTab } from 'shared/components/common/headerTabs/HeaderTabs';
import { IEntity } from 'shared/models/Entity';
import { PayRateType, PayType } from 'shared/models/PaySettings';
import { IStatusEntity } from 'shared/models/Status';
import { IClient } from 'store/entities/clients/clientsModel';

export enum OfferLetterStatusSlug {
    Expired = 'expired',
    Outstanding = 'outstanding',
    Rescinded = 'rescinded',
    PendingManagerApproval = 'pending_manager_approval',
    Accepted = 'accepted',
    Declined = 'declined',
    PendingPayRateApproval = 'pending_pay_rate_approval',
    RejectedPayRate = 'pay_rate_rejected',
}

export const OfferLetterStatusNameMap: Record<OfferLetterStatusSlug, string> = {
    [OfferLetterStatusSlug.Outstanding]: 'Outstanding',
    [OfferLetterStatusSlug.Declined]: 'Declined',
    [OfferLetterStatusSlug.Accepted]: 'Accepted',
    [OfferLetterStatusSlug.PendingManagerApproval]: 'Pending I-9',
    [OfferLetterStatusSlug.Expired]: 'Expired',
    [OfferLetterStatusSlug.Rescinded]: 'Rescinded',
    [OfferLetterStatusSlug.PendingPayRateApproval]: 'Pending',
    [OfferLetterStatusSlug.RejectedPayRate]: 'Rejected',
};

export interface IOfferLetterTab extends IHeaderTab {}

export const allOfferLetterTabName = 'All';

export const offerLetterTabsSorted: IOfferLetterTab[] = [
    {
        label: OfferLetterStatusNameMap[OfferLetterStatusSlug.Outstanding],
        value: OfferLetterStatusSlug.Outstanding,
    },
    {
        label: OfferLetterStatusNameMap[OfferLetterStatusSlug.Declined],
        value: OfferLetterStatusSlug.Declined,
    },
    {
        label: OfferLetterStatusNameMap[OfferLetterStatusSlug.Accepted],
        value: OfferLetterStatusSlug.Accepted,
    },
    {
        label: OfferLetterStatusNameMap[OfferLetterStatusSlug.PendingManagerApproval],
        value: OfferLetterStatusSlug.PendingManagerApproval,
    },
    {
        label: OfferLetterStatusNameMap[OfferLetterStatusSlug.Expired],
        value: OfferLetterStatusSlug.Expired,
    },
    {
        label: OfferLetterStatusNameMap[OfferLetterStatusSlug.Rescinded],
        value: OfferLetterStatusSlug.Rescinded,
    },
    {
        label: allOfferLetterTabName,
        value: allOfferLetterTabName,
    },
];

export interface IStatus extends IStatusEntity<OfferLetterStatusSlug> {
}

export enum OfferLetterRejectionReason {
    IncorrectPayRate = 'incorrect_pay_rate',
    IncorrectStartDate = 'incorrect_start_date',
    NoLongerInterested = 'no_longer_interested',
    AcceptedAnotherOffer = 'accepted_another_offer',
}

export const rejectReasons: {
    label: string;
    value: OfferLetterRejectionReason;
}[] = [
    {
        label: 'Incorrect Pay rate',
        value: OfferLetterRejectionReason.IncorrectPayRate,
    },
    {
        label: 'Incorrect Start Date',
        value: OfferLetterRejectionReason.IncorrectStartDate,
    },
    {
        label: 'No longer interested',
        value: OfferLetterRejectionReason.NoLongerInterested,
    },
    {
        label: 'Accepted other offer',
        value: OfferLetterRejectionReason.AcceptedAnotherOffer,
    },
];

export enum OfferLetterRescissionReason {
    PositionNoLongerAvailable = 'position_no_longer_available',
    AssignmentCancelled = 'assignment_cancelled',
    Disqualified = 'disqualified',
    Unresponsive = 'unresponsive',
    CandidateWithdrew = 'candidate_withdrew',
    Other = 'other',
}

export const RescissionReasons: {
    label: string;
    value: OfferLetterRescissionReason;
}[] = [
    {
        label: 'Position No Longer Available',
        value: OfferLetterRescissionReason.PositionNoLongerAvailable,
    },
    {
        label: 'Assignment Cancelled',
        value: OfferLetterRescissionReason.AssignmentCancelled,
    },
    {
        label: 'Disqualified',
        value: OfferLetterRescissionReason.Disqualified,
    },
    {
        label: 'Unresponsive',
        value: OfferLetterRescissionReason.Unresponsive,
    },
    {
        label: 'Candidate Withdrew',
        value: OfferLetterRescissionReason.CandidateWithdrew,
    },
    {
        label: 'Other',
        value: OfferLetterRescissionReason.Other,
    },
];

export interface IOfferLetter extends IEntity {
    status: IStatus;
    first_name: string;
    last_name: string;
    email: string;
    start_date: string;
    offer_date: string;
    rejection_reason: OfferLetterRejectionReason | null;
    managers: IApprover[];
    cell_phone: string;
    pay_rate_value: number;
    pay_rate_type: PayRateType;
    pay_type: PayType;
    pay_range_id?: string;
    accepted_at: string;
    rejected_at: string;
    client_id: string;
    user_id: string;
    creator_id?: string;
    offer_letter_terms_accepted_at: string;
    viewed_by_employee_at: string;
    assigned_user_id: string;
    rescinded_by: string | null;
    rescission_reason: OfferLetterRescissionReason | null;
    rescission_reason_custom_text: string;
    pay_rate_rejection_reason: string;

    offer_letter_template_id?: string;
    range_of_hours?: number;
    employment_type?: string;
    working_conditions_id?: string | null;
    working_conditions?: string;
    physical_demands?: string;
    physical_demands_id?: string | null;
    background_checks?: string;
    background_checks_id?: string | null;
    employee_number?: string | null;
    custom_field_value_ids?: string[];
}

export interface IApprover {
    user_id: string;
    manager_level: number;
}

export interface ICreateOfferRequest {
    first_name: string;
    last_name: string;
    pay_rate_value: number;
    pay_rate_type: PayRateType;
    pay_type: PayType;
    start_date: string;
    offer_date?: string;
    cell_phone: string;
    managers: IApprover[];
    offer_letter_template_id?: string;
    range_of_hours?: number;
    employment_type?: string;
    working_conditions_id?: string | null;
    working_conditions?: string;
    physical_demands?: string;
    physical_demands_id?: string | null;
    background_checks?: string;
    background_checks_id?: string | null;
    client_id?: string;
    employee_number?: string;
    custom_field_value_ids?: string[];
}

export interface IUpdateOfferRequest {
    first_name: string;
    last_name: string;
    email: string;
    status: string;
    location_id?: string;
    position_id?: string;
    position_value_id?: string;
    location_value_id?: string;
    department_value_id?: string;
    offer_date: string;
    cell_phone: string;
    rejection_reason: string | null;
    offer_letter_terms_accepted_at: string;
    viewed_by_employee_at: string;
    rescission_reason_custom_text: string;
    rescission_reason: string;
    pay_rate_rejection_reason: string;
    do_not_send_employee_email?: boolean;
}

export interface ICreateOfferLetterBackend extends ICreateOfferRequest {
    email: string;
}

export interface ICreateEmployeeOfferLetterBackend extends ICreateOfferRequest {
    user_id: string;
}

export interface IGetOfferLetterParams {
    self?: boolean;
    sort?: string;
    range?: string;
    filters?: string;
    client_id?: string;
    job_order_id?: string;
    status_id?: string;
    //Comma-delimited list
    status_ids?: string;
    status?: OfferLetterStatusSlug;
    //Comma-delimited list OfferLetterStatusSlug
    statuses?: string;
    search?: string;
    creator_id?: string;
    start_date?: string;
    prism_employee_id?: string;
    approver_id?: string;
    created_since?: string,
}

export type OfferLetterTabValue = OfferLetterStatusSlug | typeof allOfferLetterTabName | null;

export interface IOfferLetterFilters {
    startDate: string;
    startDateGte: string;
    startDateLte: string;
    creatorId: string;
    statusSlug: OfferLetterTabValue;
    search: string;
    jobOrderId: null,
    approverId: null,
    created_since: null,
}

export interface IOfferLetterWithLinked {
    linked: {
        clients: Array<IClient>;
    };
    offer_letters: Array<IOfferLetter>;
    total_items: number;
}

export interface IOfferLetterPagePayload {
    offerLetterIds: string[];
    total: number;
}

export interface IOnboardEmployeeProfileRequest {
    offer_letter_id: string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    tax_id: string;
    county: string;
    state: string;
    city: string;
    address1: string;
    address2: string;
    zip_code: string;
}

export interface IRejectPayRangePayload {
    offerId: string;
    reason: string;
}

export interface IEmployeeProfilesRequest {
    sort?: string;
    range?: string;
    filters?: string;
    client_id?: string;
}
